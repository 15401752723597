import React, { useEffect, useState } from 'react';
import CertsListComponent from './components/CertsListComponent';
import NewsListComponent from './components/NewsListComponent';
import data from './api/data.json';
import certifications from './api/certifications.json';
import CheckboxArea from './components/Checkbox';
import { FaArrowCircleUp } from 'react-icons/fa';
import './components/App.css';
import ScrollArrow from './components/ScrollScrow';
import Select from 'react-select'
import SourceFeedComponent from './components/SourceFeedComponent';
import ReactGA from 'react-ga';

function App() {

    const [certs, setCerts] = useState([]);
    const [newsArticle, setNewsArticle] = useState([]);
    const [allArticles, setAllArticles] = useState([]);
    const [todaysArticles, setTodaysArticles] = useState([]);
    const [weeklyArticles, setWeeklyArticles] = useState([]);
    const [monthlyArticles, setMonthlyArticles] = useState([]);
    const [sourceFeed, setSourceFeed] = useState([]);
    const [searchItems, setSearchItems] = useState([]);
    const [showScroll, setShowScroll] = useState(false);
    const [tags, setTags] = useState([]);
    const [isCheck, setIsCheck] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [isTodaysActive, setIsTodaysActive] = useState(true);
    const [isWeeklyActive, setIsWeeklyActive] = useState(false);
    const [isMonthlyActive, setIsMonthlyActive] = useState(false);

    useEffect(() => setCerts(certifications), []);
    useEffect(() => setTags(tags), []);

    const TRACKING_ID = "G-TTESX4RLLQ"; // YOUR_OWN_TRACKING_ID
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(window.location.pathname);

    useEffect(() => {
        fetch("https://api.asyncq.com/news/list?dateFilter=week")
            .then(res => {
                console.log("result: " + res);
                return res.json();
            })
            .then(
                (result) => {
                    setWeeklyArticles(result);
                    setAllArticles(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    //   setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    useEffect(() => {
        fetch("https://api.asyncq.com/news/list?dateFilter=month")
            .then(res => {
                console.log("result: " + res);
                return res.json();
            })
            .then(
                (result) => {
                    setMonthlyArticles(result);
                    setAllArticles(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    //   setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    useEffect(() => {
        fetch("https://api.asyncq.com/news/list?dateFilter=today")
            .then(res => {
                console.log("result: " + res);
                return res.json();
            })
            .then(
                (result) => {
                    setTodaysArticles(result);
                    setNewsArticle(result);
                    setAllArticles(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    //   setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    useEffect(() => {
        fetch("https://api.asyncq.com/news/source")
            .then(res => {
                console.log("result: " + res);
                return res.json();
            }).then(
                (result) => { setSourceFeed(result); },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    //   setIsLoaded(true);
                    setError(error);
                }
            )
    }, [])

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 400) {
            setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 400) {
            setShowScroll(false)
        }
    };

    window.addEventListener('scroll', checkScrollTop)

    const level = ["Level", "Beginner", "Intermediate", "Expert"];
    //   const tags = ["Area", "Java", "Spring boot", "GCP", "Data engineer"];
    const options = [{ value: 'Beginner', label: 'Beginner' },
    { value: 'Intermediate', label: 'Intermediate' },
    { value: 'Expert', label: 'Expert' },]


    const handleCallback = (result) => {
        setTags([...tags, result.tags]);
        setSearchItems([...searchItems, result.data]);
        setIsCheck(result.isChecked);
    }

    const handleChange = (selectedOption) => {
        console.log("selectedOption : " + selectedOption);
        setSelectedOption(selectedOption);
        console.log(`Option selected:`, selectedOption);
        const filterData = certifications.filter(cert => {
            const tags = cert.tags;
            console.log(selectedOption.value);
            console.log(tags);
            if (tags.includes(selectedOption.value)) {
                console.log("cert: " + cert);
                return cert;
            }
        });
        console.log(filterData.length);
        setCerts(filterData);
    }

    const filterSearch = (source) => {
        if (source == "All") {
            setNewsArticle(allArticles);
            return;
        }
        const filtered = allArticles.filter(a => a.provider == source);
        setNewsArticle(filtered);
    }

    const todaysList = () => {
        setIsTodaysActive(true);
        setIsWeeklyActive(false);
        setIsMonthlyActive(false);

        setNewsArticle(todaysArticles);
        setAllArticles(todaysArticles);
    }

    const monthlyList = () => {
        setIsTodaysActive(false);
        setIsWeeklyActive(false);
        setIsMonthlyActive(true);
        setNewsArticle(monthlyArticles);
        setAllArticles(monthlyArticles);
    }

    const weeklyList = () => {
        setIsTodaysActive(false);
        setIsWeeklyActive(true);
        setIsMonthlyActive(false);
        setNewsArticle(weeklyArticles);
        setAllArticles(weeklyArticles);
    }

    return (
        <div className='App'>
            <div>
                <ScrollArrow />
            </div>
            <div className='relative'>
                <div className='sticky top-0 left-0 right-0'>
                    <header className='bg-white mb-6 sticky w-full border-solid border-2 border-gray-300'>
                        < div className='flex ml-5'>
                            <img className="h-16 w-32" src='./source_feed_wrk.png'></img>
                        </div>
                        {/* <h1 className='text-teal-700 font-bold ml-5'> Best of Tech Feeds!</h1> */}

                        {/* <img src="/assets/images/bg-header-desktop.svg" alt="bg-mage" /> */}
                    </header>
                </div>
                <div className='flex'>
                    {/* <div className='flex flex-col shadow-lg ml-8'>
                        <div className='ml-2 fixed invisible lg:visible'>
                            <h1 className="text-teal-700 font-bold">Popular Tags</h1>
                            <CheckboxArea className="text-blue-700" text="Cloud" data={certs} parentCallBack={handleCallback} />
                            <CheckboxArea text="Data" data={certs} parentCallBack={handleCallback} />
                            <CheckboxArea text="Backend" tags={tags} data={certs} parentCallBack={handleCallback} />
                            <CheckboxArea text="Language" tags={tags} data={certs} parentCallBack={handleCallback} />
                            {/* <Select value={selectedOption} onChange={this.handleChange} options={options} />
                            <Select value={selectedOption} options={options} onChange={handleChange} /> */}
                    {/* </div>
                    </div>  */}
                    {/* <div className='grid grid-cols-1 place-items-center ml-64'>
                        {
                            certs.length > 0 ? certs.map((cert) => <CertsListComponent cert={cert} key={cert.id} />) :
                                <h3>No Result Found</h3>
                        }
                    </div> */}
                    <div className='flex flex-col ml-8 fixed invisible lg:visible'>
                        <h1 className="ml-8 text-gray-700 font-bold">Sources({sourceFeed.length})</h1>
                        <div className='m-2 bg-white shadow-lg h-screen overflow-auto'>
                            <div>
                                <button className="text-teal-500 bg-teal-100 font-bold m-3 p-2 rounded" onClick={() => filterSearch("All")}>All <span className='text-gray-500'>({allArticles.length})</span></button>
                            </div>
                            {
                                sourceFeed.map((source) => {
                                    return <SourceFeedComponent source={source} callback={filterSearch} />
                                })
                            }
                        </div>
                    </div>


                    <div className='grid grid-cols-1 m-auto'>
                        <div className='max-w-3xl'>
                            <button className={isTodaysActive == true ? 'w-1/3 border-r-4 border-b-4 border-indigo-500 bg-gray-300 font-bold' : 'w-1/3 border-r-4  border-indigo-500 bg-white'} onClick={todaysList}>Today</button>
                            <button className={isWeeklyActive == true ? 'w-1/3 border-r-4 border-b-4 border-indigo-500 bg-gray-300 font-bold' : 'w-1/3 border-r-4 border-indigo-500 bg-white'} onClick={weeklyList} >This Week</button>
                            <button className={isMonthlyActive == true ? 'w-1/3 border-r-4  border-indigo-500 bg-gray-300 font-bold' : 'w-1/3 border-r-4 border-indigo-500 bg-white'} onClick={monthlyList}>This Month</button>
                        </div>
                        <h1 className='m-4 text-gray-700 font-bold'>Total Published :
                            <span className='text-indigo-700 font-bold underline m-1'>{newsArticle.length} articles</span>
                        </h1>
                        {/* {
                            newsArticle.length > 0 ? newsArticle.map((news) => {
                                return <NewsListComponent news={news} key={news.id} />
                            }) :
                                <h3>No Result Found</h3>
                        } */}
                        {
                            newsArticle.map((news) => {
                                return <NewsListComponent news={news} key={news.id} />
                            })
                        }
                    </div>
                </div >
            </div >
        </div>
    );
}
export default App;