import React, { useState } from "react";
import certifications from '../api/certifications.json';

function CheckboxArea(props) {
    const [checked, setChecked] = useState(false);
    const handleChange = () => {
        var isChecked = !checked;
        var tagText = props.text;
        var tags = props.tags;
        const data = props.data;
        if (isChecked) {
            const filterTarget = certifications.filter(cert => {
                const tags = cert.tags;
                if (tags.includes(tagText)) {
                    console.log(cert);
                    return cert;
                }
            });

            // const filterResult = { ...data, ...filterTarget };
            // console.log(filterResult);
            console.log("filterTarget: " + filterTarget);
            if (filterTarget.length > 0) {
                const result = { isChecked: isChecked, data: filterTarget, tags: tagText };
                props.parentCallBack(result);
            } else {
                const result = { isChecked: isChecked, data: data, tags: tagText };
                props.parentCallBack(result);
            }
        } else {
            const result = { isChecked: isChecked, data: certifications, tags: tagText };
            props.parentCallBack(result);
        }
        setChecked(isChecked);
    }
    return (
        <div className="m-4">
            <input name="Popular" type="checkbox" className="w-4 h-4 rounded-md" onChange={handleChange} />
            <label className="text-base text-teal-700 font-bold m-1 p-1">{props.text}</label>
        </div>
    );
};


export default CheckboxArea;