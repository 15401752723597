import React from "react";
import ScrollArrow from "./ScrollScrow";
const NewsListComponent = ({ news }) => (
    // <div className="bg-white shadow-lg m-4 p-6">
    //     <div>
    //         <img src={`/assets/${cert.logo}`} alt={cert.company} />
    //     </div>
    //     <div className="flex flex-col justify-between ml-4">
    //         <h3 className="font-bold text-teal-500">{cert.company}</h3>
    //         <h3 className="font-bold text-xl">{cert.position}</h3>
    //         <p className="text-gray-700">{cert.postedAt} · {cert.contract} · {cert.location}</p>
    //     </div>
    //     <div className="flex items-center">
    //         {cert.languages ? cert.languages.map((language) => <span className="text-teal-500 bg-teal-100 font-bold m-3 p-2 rounded">{language}</span>) : ''}
    //         {cert.tools ? cert.tools.map((tool) => <span className="text-teal-500 bg-teal-100 font-bold m-3 p-2 rounded">{tool}</span>) : ''}
    //     </div>
    //     <p></p>
    // </div>
    <a href={news.link} target="_blank">
        <div className="max-w-2xl flex content-between bg-white shadow-lg my-2 p-1 rounded">
            {/* <div>
            <img src={`/assets/${cert.logo}`} alt={cert.company} />
        </div> */}
            <div className="flex flex-col justify-between ml-8 ">
                <div className="flex flex-start flex-row">
                    <h3 className="font-bold text-indigo-700 text-xl">{news.title}</h3>
                    {/* <div className="flex items-center m-auto">
                        <span className="text-white bg-indigo-400 font-bold p-2 rounded">{cert.level}</span>
                    </div> */}

                </div>

                <div className="flex flex-row  items-center">
                    <p className="text-gray-700 font-bold">Source:</p>
                    <span className="text-teal-500 bg-teal-100 font-bold m-3 p-2 rounded">{news.provider}</span>
                    {/* <img className="object-fill h-20 w-20 " src="https://cdn.confluent.io/wp-content/uploads/seo-logo-meadow.png" alt="BigCo Inc. logo" /> */}
                    <p className="text-gray-700 font-bold flex-right text-sm"> Published Date : {news.publishedDate.substring(0, 10)}</p>
                </div>
                <div className="flex flex-row  items-center">
                    <p className="text-gray-700 font-bold text-sm">Tags:</p>
                    {news.categories.map((tag) => <span className="text-gray bg-white font-bold m-3 p-2 border-solid border-gray-600 border-2 rounded text-sm">{tag ? (tag == 'Uncategorized' ? news.provider : tag) : news.provider}</span>)}
                </div>

                {/* <p className="text-gray-700">{cert.excerpt}</p> */}
                {/* <h3 className="font-bold text-xl">{cert.position}</h3> */}
            </div>
            {/* <div className="flex items-center">
                {cert.languages ? cert.languages.map((language) => <span className="text-teal-500 bg-teal-100 font-bold m-3 p-2 rounded">{language}</span>) : ''}
                {cert.tools ? cert.tools.map((tool) => <span className="text-teal-500 bg-teal-100 font-bold m-3 p-2 rounded">{tool}</span>) : ''}
            </div> */}
            <p></p>
        </div>
    </a>
)
export default NewsListComponent;